import JustValidate from "just-validate";
import Inputmask from "inputmask";

export const validateForms = (selector, rules, afterSend) => {
  const form = document?.querySelector(selector);
  const telSelector = form?.querySelector('input[type="tel"]');

  if (!form) {
    console.error("Нет такого селектора!");
    return false;
  }

  if (!rules) {
    console.error("Вы не передали правила валидации!");
    return false;
  }

  if (telSelector) {
    const inputMask = new Inputmask("+7 (999) 999-99-99");
    inputMask.mask(telSelector);

    for (let item of rules) {
      if (item.tel) {
        item.rules.push({
          rule: "function",
          validator: function () {
            const phone = telSelector.inputmask.unmaskedvalue();
            return phone.length === 10;
          },
          errorMessage: item.telError,
        });
      }
    }
  }

  const validation = new JustValidate(selector);

  for (let item of rules) {
    validation.addField(item.ruleSelector, item.rules);
  }

  validation.onSuccess((ev) => {
    let formData = new FormData(ev.target);

    let xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          if (afterSend) {
            afterSend();
          }
          if (xhr.response == 1) {
            alert(
              "Ваша заявка отправлена! Наш менеджер в течение 24 часов с вами свяжется. Для более оперативного ответа просим вас позвонить по тел. горячей линии 8 800 600 98 28 (ежедневно с 8.00 до 21.00 МСК"
            );
          }
          if (xhr.response == 2) {
            alert("Заявка с таким номером телефона уже на рассмотрении!");
          }
          if (xhr.response == 0) {
            alert(
              "Произошла ошибка при отправке запроса. Повторите попытку позже!"
            );
          }
        }
        if (xhr.status != 200) {
          alert(
            "Произошла ошибка при отправке запроса. Повторите попытку позже!"
          );
        }
      }
    };

    xhr.open("POST", "https://ak-i.ru/ac/php/send3_utm.php", true);
    xhr.send(formData);
    ev.target.reset();
  });
};
